@use 'layers';
@use 'colors';

$table-border-color: colors.$primary-50;

.column {
  align-self: center;
  vertical-align: middle;
  padding: 0.75em 1em;
  display: inline-block;

  &.flex {
    display: inline-flex;
  }

  &.centered {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  text-overflow: ellipsis;
  overflow: hidden;
}

.row {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background-color: colors.$primary-25;
  border-bottom: 1px solid $table-border-color;

  &.selected {
    background-color: colors.$button-primary-dark-pressed;
  }
}

.rowExpanded {
  padding: 0.75em 1em;
  background-color: colors.$button-primary-dark-pressed;
}

.row-hover {
  &:hover {
    background-color: colors.$background-hover;
  }
}

.columnSeparator {
  border-right: 1px solid $table-border-color;
}

.header {
  position: sticky;
  top: 0;
  background-color: colors.$primary-25;
  white-space: nowrap;
  border-bottom: 1px solid $table-border-color;
  z-index: layers.$table-header-layer;
  width: 100%;
}

.cell {
  padding: 0.5em 1em;
  border: 1px solid $table-border-color;
}

.cellCentered {
  text-align: center;
  margin: 0.4em;
}

.infoBox {
  color: colors.$header-background;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  * {
    color: colors.$header-background;
    display: flex;
    align-items: normal;
    justify-content: center;
  }
}

.darkInfoBox {
  color: colors.$panel-background;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: center;
  * {
    color: colors.$panel-background;
    display: flex;
    align-items: normal;
    justify-content: center;
  }
}

button.left-padded {
  margin-left: 1rem;
}

.sticky-end-column {
  box-shadow: rgba(0,0,0,0.1) -3px 0 5px 0;
}
