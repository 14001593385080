@use "scss/colors";
@use "scss/variables";

.dashboard-box-list {
  display: flex;
  gap: variables.$default-gap;
}

.dashboard-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: colors.$component-background;
  border-radius: variables.$default-border-radius;

  p {
    margin: 0;
  }

  .dashboard-value {
    font-size: 1.6rem;
  }

  &.disabled {
    background-color: colors.$component-background;
    color: colors.$text-black-disabled;
  }
}
