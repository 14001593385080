@use 'scss/colors';
@use 'scss/variables';

.nodes-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1.2rem 1rem;
  background-color: colors.$primary-25;
  color: colors.$primary-text-color;
  margin-bottom: 0.5rem;
  border-radius: variables.$default-border-radius;

  &:not(.no-highlight) {
    &:hover {
      background: colors.$card-hover;
    }
  }

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;

    & > .contract-name {
      width: 70%;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  .card-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-detail-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: variables.$default-gap;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: colors.$component-background;
    margin-right: 1rem;
    border: 2px solid colors.$panel-background;

    &.critical {
      border: 2px solid colors.$event-critical;
    }
  }

  .data {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    span {
      max-width: 256px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        margin-right: 1rem;
      }

      .center {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        em {
          color: colors.$header-background;
          margin-right: 0.1rem;
        }

      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -8px;
  }
}

.highlighted {
  .nodes-card, &.nodes-card {
    background: colors.$card-hover;
  }
}
.highlighted-light {
  .nodes-card, &.nodes-card {
    background: colors.$card-highlighted;
  }
}
