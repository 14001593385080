@use 'scss/colors';

.status-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  border-radius: 20px;

  background: colors.$event-info;
  color: white !important;

  &.attention {
    background: colors.$event-attention;
    color: white !important;
  }

  &.critical {
    background: colors.$event-critical;
    color: white !important;
  }

  &.success {
    background: colors.$event-success;
    color: white !important;
  }

  &.disabled {
    background: colors.$event-disabled;
    color: white !important;
  }
}
