@use 'colors';
@use 'variables';
@use 'typography';

.d-inline {
  display: inline-block;
}

.d-inline-flex {
  display: inline-flex;
}

.truncate {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-margin {
  margin: 0 !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.status-color {
  &.bg-Active {
    background-color: rgba(colors.$event-success, 0.3);
  }

  &.bg-Pending {
    background-color: rgba(colors.$event-attention, 0.3);
  }

  &.bg-Rejected {
    background-color: rgba(colors.$event-critical, 0.3);
  }
}

.header-row {
  background-color: colors.$sorting-header-background;
  display: flex;
  min-height: 5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 2rem;
  gap: variables.$default-gap;

  .start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: variables.$default-gap;
  }

  .header-title {
    @extend .subtitle;
    @extend .bold;
    color: colors.$secondary-text-color;
    padding-right: 2rem;
  }

  &.sub-header {
    min-height: 3.5rem;
    background-color: colors.$header-background;
  }

  .actions {
    display: flex;
    gap: variables.$default-gap;
    align-items: center;
    justify-content: flex-end;
  }
}

.cdk-virtual-scroll-content-wrapper {
  padding: 0 !important;
}

.spin-animation {
  animation: spinAnimation 1s linear infinite;
}

@keyframes spinAnimation {
  100% {
    transform: rotate(360deg);
  }
}

.pulse-animation {
  animation: pulseAnimation 1.5s linear infinite;
}

@keyframes pulseAnimation {
  50% {
    opacity: 0.2;
  }
}
