@use "sass:color";
@use 'scss/_colors.scss';
@use 'scss/_variables.scss';
@use 'typography.scss';
@use 'theme-palette';

mat-hint.error {
  color: colors.$event-critical;
}

.mat-mdc-form-field-flex {
  background-color: white;
  padding: 0 1em;
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: inherit;
}

.mdc-text-field {
  border-radius: 0 !important;
}

mat-card {
  border-radius: variables.$default-border-radius !important;
}

.mat-mdc-card {
  padding: 16px;
}

.mat-mdc-form-field-prefix {
  padding-right: 0.25rem;
}

.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
  align-self: unset !important;
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-subscript-wrapper {
  top: calc(100% - 2em);
  left: 0.5em;
}

// Style mat-table
// Make it look like the NODES way
.mat-mdc-table {
  //background-color: $component-background;
  background-color: theme-palette.$primary-25;
  overflow-x: scroll;
}

.mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row {
  min-width: 1000px;
}

.mat-mdc-header-cell, .mat-mdc-footer-cell {
  padding: 0.8em 1em !important;
  color: theme-palette.$primary-500;
  font-weight: 700;
  text-align: center !important;
  border: 1px solid colors.$light-border;
}

.mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid colors.$light-border !important;
}

.mat-mdc-footer-cell {
  // lets remove the borders so it stands out more
  border: none;
  border-top: 1px solid colors.$light-border;
}

//.mdc-data-table__row, .mdc-data-table__header-row, .mat-mdc-footer-row {
//  height: 40px !important;
//}

.mdc-data-table__row:hover {
  background-color: colors.$button-primary-dark-pressed !important;
}

.compact-table {
  .mdc-data-table__row, .mat-mdc-header-row {
    height: fit-content !important;

    td, th {
      font-size: 0.75rem !important;
    }

    .mat-mdc-cell {
      padding: 0.5em 1em !important;
    }
  }
}

.compact-form-field {
  .mat-mdc-form-field,
  &.mat-mdc-form-field {
    > .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.mat-mdc-cell {
  color: theme-palette.$primary-500;
  padding: 0.75em 1em !important;
  font-size: 0.875rem;
  text-align: left !important;
  border: 1px solid colors.$light-border;

  &.centered {
    text-align: center !important;
  }

  &.right {
    text-align: right !important;
  }
}

th.mat-mdc-table-sticky {
  top: -1px !important;
}

::ng-deep .mat-mdc-sort-header-container:not(.mat-mdc-sort-header-sorted) .mat-mdc-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}


// Allow a smaller version of the mat-checkbox. Used for places where the checkbox is not directly
// a part of a form, but rather supports another form element, like a dropdown.
mat-checkbox {
  &.mat-small {
    .mdc-checkbox {
      margin: 2px 0 2px 2px;
      padding: 0.25rem 0 0.25rem 0.25rem;

      .mat-mdc-checkbox-touch-target,
      .mdc-checkbox__native-control {
        height: 25px;
        width: 25px;
      }

      .mdc-checkbox__background {
        height: 13px;
        width: 13px;
        top: 5px;
        left: 5px;
      }

      .mdc-checkbox__ripple {
        height: 27px !important;
        width: 27px !important;
        top: -2px;
        left: -2px;
      }
    }
  }
}

.mat-mdc-sort-header-button {
  border: inherit !important;
  background: inherit !important;
  display: inherit !important;
  align-items: inherit !important;
  padding: inherit !important;
  cursor: inherit !important;
  outline: inherit !important;
  font: inherit !important;
  color: inherit !important;
  text-transform: inherit !important;
}

// Need to override the Today mark, since it goes off when the day is not the same as the UTC day.
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border: none !important;
}

.mat-mdc-datepicker-toggle,
mat-calendar {
  button:hover {
    background-color: inherit;
  }
}

ngx-mat-datetime-content,
mat-datepicker-toggle {
  button {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: colors.$background-hover !important;
    }
  }
}


mat-slide-toggle {
  button {
    &:hover, &:disabled {
      background-color: inherit;
    }
  }
}

button[matchipremove]:hover {
  background-color: inherit;
}

/** TOGGLE BUTTONS **/
mat-button-toggle-group > mat-button-toggle {
  &.mat-button-toggle-checked > button.mat-button-toggle-button {
    background-color: colors.$button-primary;
    color: colors.$secondary-text-color;

    &:hover {
      background-color: colors.$button-primary-pressed;
      color: colors.$secondary-text-color;
    }

    & mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
      color: colors.$secondary-text-color;
    }
  }

  & > button.mat-button-toggle-button {
    border-radius: 0;
    background-color: colors.$button-primary-dark;
    color: colors.$primary-text-color;

    &:hover {
      background-color: colors.$button-primary-dark-pressed;
    }
  }
}

mat-paginator {
  display: flex !important;
  justify-content: center !important;
  background: colors.$panel-background;
  border-top: 1px solid colors.$component-border;

  button {
    background-color: inherit;
    color: inherit;

    &:hover {
      color: inherit;
      background-color: inherit;
    }

    &:disabled {
      color: inherit;
      background-color: inherit;
    }
  }
}

.mat-mdc-button {
  border-radius: variables.$default-border-radius !important;

  &.mat-mdc-button:not(:disabled) {
    color: inherit !important;
  }
}


.mdc-menu-surface {
  border-radius: variables.$default-border-radius !important;
}

// steppers
.mat-step-header, mat-stepper, mat-horizontal-stepper {
  background-color: colors.$panel-background !important;
  max-width: 100%;
}

.mat-stepper-horizontal-line {
  border-top-color: colors.$header-background !important;
}

.mat-step-header .mat-step-icon {
  background-color: inherit !important;
  color: inherit !important;
  border: 1px solid colors.$header-background;

  &.mat-step-icon-selected, &.mat-step-icon-state-edit {
    background-color: colors.$header-background !important;
    color: white !important;
  }
}

.mat-step-text-label {
  color: colors.$header-background;
}

// Accordions/Expansion panels
.dark {
  .mat-expansion-panel {
    border-radius: 0 !important;
    background-color: colors.$panel-background !important;
    color: colors.$primary-text-color !important;
  }

  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-expansion-panel-header-title {
    color: colors.$primary-text-color;
  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-calendar {
  border-radius: variables.$default-border-radius;
}

.mat-mdc-checkbox .mdc-form-field {
  color: colors.$primary-text-color !important;
}

.mat-mdc-checkbox.mat-small {
  .mdc-form-field {
    label {
      font-size: 0.75rem !important;
      margin-bottom: 3px !important;
    }
  }
}

.mat-mdc-tab-disabled {
  cursor: inherit !important;

  &:hover {
    background: colors.$sorting-header-background !important;
  }
}

.dark-theme {
  .mat-mdc-table {
    background: inherit;
    color: white;

    .mat-mdc-header-cell,
    .mat-mdc-footer-cell,
    .mat-mdc-cell {
      color: white;
      border: none;
    }

    .mat-mdc-header-cell {
      font-weight: 600 !important;
    }

    .mdc-data-table__row:hover {
      background-color: theme-palette.$primary-500 !important;
    }

    .compact-table .mdc-data-table__row:hover {
      background-color: theme-palette.$primary-500;
    }
  }

  .mdc-data-table__row:last-child .mdc-data-table__cell,
  .mdc-data-table__cell, .mdc-data-table__header-cell {
    border-bottom: 1px solid theme-palette.$primary-900 !important;
  }
}

// Progress bar
mat-progress-bar {
  border-radius: variables.$default-border-radius !important;
  overflow: clip;
}
.mdc-linear-progress__buffer {
  background-color: theme-palette.$primary-400 !important;
}

.mdc-linear-progress__bar-inner {
  border-color: theme-palette.$primary-50 !important;
}

.critical {
  .mdc-linear-progress__buffer {
    background-color: color.adjust(colors.$event-critical, $lightness: 40%) !important;
  }

  .mdc-linear-progress__bar-inner {
    border-color: colors.$event-critical !important;
  }
}

.success {
  .mdc-linear-progress__buffer {
    background-color: color.adjust(colors.$event-success, $lightness: 60%) !important;
  }

  .mdc-linear-progress__bar-inner {
    border-color: colors.$event-success !important;
  }
}

.disabled {
  .mdc-linear-progress__buffer {
    background-color: color.adjust(theme-palette.$gray-600, $lightness: 65%) !important;
  }

  .mdc-linear-progress__bar-inner {
    border-color: theme-palette.$gray-600 !important;
  }
}

mat-progress-bar,
.mdc-linear-progress__buffer,
.mdc-linear-progress__buffer-bar,
.mdc-linear-progress__bar {
  height: 8px !important;
}

.mdc-linear-progress__bar-inner {
  // Width is one higher to avoid rounding errors in chrome
  border-top-width: 9px !important;
}

.mat-mdc-optgroup-label {
  color: colors.$primary-text-color;
  font-size: 0.875rem;
  font-weight: 600;
}
