@use "scss/colors";

.dialog-wrapper {
  padding: 30px;

  [mat-dialog-title] {
    margin: 0;
  }
}


.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  border-top: solid 1px colors.$component-border;
  flex: 0 0 72px;

  button {
    margin-left: 0.5rem;
  }
}


::ng-deep .error-dialog-container {
  mat-dialog-container {
    border-radius:0;
  }
}
