.summary-row {
  display: flex;

  .key {
    width: 45%;
  }
  
  .value {
    width: 55%;

    .original-value {
      opacity: 0.4;
    }
  }

  p {
    margin: 0;
  }
}