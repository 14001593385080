/* You can add global styles to this file, and also use other style files */
// material stuff
@use "scss/mat-theme";
@use "./scss/material-overrides";
@use 'material-design-icons/iconfont/material-icons.css';
//// end material stuff

@use "./scss/variables";
@use "./scss/colors";
@use "scss/typography";
@use "./scss/button";
@use "./scss/layers";
@use "./scss/icons";
@use "./scss/common";
@use "./scss/link";
@use "./scss/icon-font";
@use '@angular/cdk/overlay-prebuilt.css';
@use "./scss/overlay";
@use "./scss/leafletStyles";
@use "./scss/table";
@use "./scss/flex";
@use "./scss/master-detail";
@use "./scss/summary-report";
@use "./scss/api-reports";
@use "./scss/nodes-form";
@use "./scss/blockquote";
@use "./scss/panel";
@use "./scss/_toastr.scss";
@use "./scss/_dialog.scss";
@use "./scss/_chips.scss";
@use "./scss/_dashboards.scss";
@use "./scss/_cards.scss";
@use "./scss/_tabs.scss";
@use "./scss/_toggle-button.scss";
@use "./scss/_csv-uploads.scss";
@use './scss/_loader.scss';
@use './scss/_menus.scss';
@use './scss/_forms.scss';

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: colors.$component-background;
}

.hidden {
  visibility: hidden;
}

//make sure width:100% takes padding into account
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.cdk-virtual-scroll-content-wrapper {
  padding: 1.5rem;
  padding-top: 0;
}

body.dialog-open app-root .page {
  filter: blur(10px) grayscale(70%);
}


.display-none {
  display: none;
}

// Focus styling for keyboard users
* {
  &:focus {
    outline: none
  }

  &:focus-visible {
    outline: colors.$event-attention 3px solid;
    outline-offset: 2px;
    z-index: 999;
  }
}
