@use 'variables';
@use 'colors';
@use 'layers';

.overlay-panel {
  background-color: colors.$component-background;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  max-height: 480px;
  border-radius: variables.$default-border-radius;
  clip-path: border-box;

  ul {
    min-width: 150px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    &:first-child {
      padding-top: 0.5rem;
    }

    &:last-child {
      padding-bottom: 0.5rem;
    }
  }
}

.calendar-panel {
  background-color: colors.$panel-background;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);

  &.under {
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent colors.$panel-background transparent;
    }
  }

  &.above {
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent colors.$panel-background transparent;
    }
  }

}

.panel {
  height: 100vh;
  width: 624px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: colors.$panel-background;
  transition: 0.5s;
  -webkit-box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  animation-name: slideIn;
  animation-duration: 0.5s;
  transform: translateX(101%);

  &.open {
    transform: translateX(0%);
  }
}

.panel.left {
  width: 480px;
  left: 0;
  transform: translateX(-101%);

  &.open {
    transform: translateX(0%);
  }

  animation-name: slideInLeft;
}


.panel__center {
  max-height: 80vh;
  max-width: 80vw;
  width: 640px;
  background-color: colors.$panel-background;
  border-radius: 2px;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.3), 0 0 24px 0 rgba(0, 0, 0, 0.22);
}

.panel__center_auto_width {
  width: auto;
}

.panel__wide_85 {
  width: auto;
  max-width: 85vw;
}

.panel__fullscreen {
  width: 100vw;
  height: 100vh;
  background-color: colors.$panel-background;
}

.panel__easteregg {
  background-color: transparent;
}

.panel__minimal {
  width: auto;
}

.dark-backdrop {
  background-color: rgba(0, 53, 97, 0.2);
}

/* The animation code */
@keyframes slideIn {
  from {
    transform: translateX(101%)
  }
  to {
    transform: translateX(0%)
  }
}

/* The animation code */
@keyframes slideInLeft {
  from {
    transform: translateX(-101%)
  }
  to {
    transform: translateX(0%)
  }
}

