@use "colors.scss";

.master-detail {
  display: flex;
  height: 100%;
  max-height: 100%;

  .master {
    width: 300px;
    background: colors.$header-background;
    color: white;
    overflow-y: scroll;

    h4 {
      padding-left: 1.5rem;
      color: white;
    }
  }

  .detail {
    background: colors.$component-background;
    flex: 1;
    padding: 1rem;
  }
}
