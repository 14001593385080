@use 'scss/colors';
@use 'scss/variables';

.csv-upload-row {
  width: 100%;
  display: flex;
  gap: variables.$default-gap;

  app-file-drop {
    flex: 1;
  }
}

.csv-example {
  margin-top: 1rem;

  table.csv-format-table {
    width: 100%;
    margin: 1rem 0 1rem 0;

    tr td:first-child {
      font-weight: bold;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid colors.$light-border;
    }
  }

  table.deprecated-format-table {
    width: 100%;
    margin: 10px 0 15px 0;
    color: colors.$secondary-text-color-disabled;

    tr:first-child {
      color: black;
    }

    tr td:first-child {
      font-weight: bold;
    }

    tr:not(:last-child) td {
      border-bottom: 1px solid colors.$light-border;
    }
  }

  .download-sample {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .legend {
      display: flex;
      flex-direction: column;
      font-size: 0.7rem;
    }

    button {
      width: 100%;
    }
  }
}

.csv-error {
  margin-top: 5px;
  color: colors.$event-critical;
  white-space: pre-line;
  max-height: 200px;
  overflow-y: auto;
}
