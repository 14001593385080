@use "scss/variables";

.n-flex {
	display: flex;
	&.n-column {
		margin: 0;
		padding: 0;
		flex-direction: column;

		&.reverse {
			flex-direction: column-reverse;
		}
	}
	&.n-row {
		flex-direction: row;

		&.reverse {
			flex-direction: row-reverse;
		}
	}
  &.gutter-default {
    gap: variables.$default-gap;
  }
  &.gutter-3 {
    gap: 3px;
  }
  &.gutter-5 {
    gap: 5px;
  }
  &.gutter-10 {
    gap: 10px;
  }
  &.gutter-15 {
    gap: 15px;
  }
  &.gutter-20 {
    gap: 20px;
  }
  &.gutter-25 {
    gap: 25px;
  }
  &.gutter-30 {
    gap: 30px;
  }
	&.wrap {
    flex-wrap: wrap;
	}
	&.nowrap {
		flex-wrap: nowrap;
	}
	& .grow {
		flex-grow: 1;
	}
	&.c-justify-left {
		justify-content: flex-start;
	}
	&.c-justify-right {
		justify-content: flex-end;
	}
	&.c-justify-center {
		justify-content: center;
	}
	&.c-justify-space-around {
		justify-content: space-around;
	}
	&.c-justify-space-between {
		justify-content: space-between;
	}
	&.c-align-center {
		align-content: center;
	}
	&.c-align-space-around {
		align-content: space-around;
	}
	&.i-align-start {
		align-items: flex-start;
  }
  &.i-align-baseline {
    align-items: baseline;
  }
	&.i-align-center {
		align-items: center;
	}
	&.i-align-end {
		align-items: flex-end;
	}
}
