@use "scss/colors";

.nodes-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 88px;
    padding: 22px;
    border-bottom: solid 1px colors.$component-border;
    width: 100%;

    h4 {
      margin: 0;
    }
  }

  .content {
    min-height: 7rem;
    overflow-x: scroll;
    width: auto;
    white-space: nowrap;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-top: solid 1px colors.$component-border;
    flex: 0 0 72px;

    & .button {
      width: 240px;
    }
  }
}
