@use 'scss/colors';

.nodes-loader {
  display: inline-block;
  border: 4px solid colors.$panel-background;
  border-radius: 50%;
  border-top: 4px solid colors.$header-background;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
  right: 50%;
}

.nodes-loader-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1000;

  .nodes-loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
