@use 'colors';

.api-response-content {
  flex: 1;
  overflow-x: hidden;

  form {
    padding: 0 1rem 1rem;
    background: colors.$header-background;
    color: colors.$panel-background;

    input[type='button'],
    input[type='submit'],
    input[type='reset'] {
      background-color: #ffffff;
      color: colors.$primary-text-color;
    }
  }

  fieldset {
    min-width: 0;
    padding-bottom: 0.5rem;
    margin: 0;
    border: 0;
    display: flex;
    flex-wrap: wrap;

    legend {
      padding-top: 1rem;
      width: auto;
      font-size: 0.875rem;
      line-height: 1.3;
      font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
    }

    label {
      width: 400px;
      padding-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        padding-right: 0.25rem;
      }
    }

    .datetime-picker {
      padding: 0;

      input {
        display: inline;
      }
    }

    &[name~="Fields"] {
      display: flex;
      flex-wrap: wrap;

      label {
        display: flex;
        align-items: center;
        width: 200px;
        justify-content: flex-start;
      }

      input {
        display: inline-block;
      }
    }

    datetime-picker input {
      display: inline;
    }
  }

  select,
  input {
    margin: 0.2rem 0;
    height: 25px;
  }

  input[type='checkbox'] {
    margin: 0 0.5rem 0 0;
  }

  .wide-table {
    height: 100%;
    overflow: auto;
  }

  table {
    border-spacing: 0;
    width: 100%;

    thead {
      th {
        background: colors.$component-background;
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    tbody {
      background: colors.$component-background;
    }

    th {
      vertical-align: middle;
      padding: 0.5em 0.75em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      font-size: small;
    }

    td {
      padding: 0.5em 0.75em;
      white-space: nowrap;
      border-right: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      font-size: small;
    }

    tbody tr {
      &:hover {
        background: rgba(174, 174, 172, 0.3);
      }

      &.warning {
        color: red;
      }
    }

    caption {
      display: none;
    }

    .cellNumber {
      text-align: right;
    }

  }

  a {
    color: inherit;
    text-decoration: underline;
    font-size: 0.75rem;
    white-space: nowrap;
    font-weight: 500;

    &:hover {
      color: inherit;
    }
  }

  input[type='button'],
  input[type='submit'],
  input[type='reset'] {
    color: colors.$primary-text-color;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    height: 34px;
    font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
    font-size: 0.875rem;
    line-height: 1.3;
    text-transform: uppercase;
  }
}
