@use "theme-palette";

@use 'scss/colors';

@font-face {
  font-family: 'schibstedgroteskMedium';
  src: url('../assets/fonts/SchibstedGrotesk-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'schibstedgroteskBold';
  src: url('../assets/fonts/SchibstedGrotesk-SemiBold.ttf') format('truetype');
}

h1 {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 1.875rem;
  line-height: 2.313rem;
  color: colors.$primary-text-color;
}

h2 {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 1.625rem;
  line-height: 2rem;
  color: colors.$primary-text-color;
}

h3 {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 1.375rem;
  line-height: 1.688rem;
  color: colors.$primary-text-color;
}

h4 {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 1.25rem;
  line-height: 1.563rem;
  color: colors.$primary-text-color;
}

.section-header {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.3;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: theme-palette.$primary-600;

  .small {
    font-size: 0.875rem;
  }
}

.bold {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-weight: 600;
}

.subtitle {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: colors.$primary-text-color;

  &.bold {
    font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
  }
}

body,
input,
textarea {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  color: colors.$primary-text-color;

  .extra-small {
    font-size: 0.6rem;
  }

  .small {
    font-size: 0.75rem;
  }

  .large {
    font-size: 1rem;
  }

  .extra-large {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .cta {
    text-decoration: underline;
  }
}

button, a[role="button"] {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 0.875rem;
  line-height: 1.3;
  text-transform: capitalize;

  &.dropdown--toggle {
    text-transform: none;
  }
}

.caption {
  font-family: 'schibstedgroteskMedium', Tahoma, sans-serif;
  font-size: 0.7rem;
  line-height: 1.3;
  color: colors.$primary-text-color;
  opacity: 0.8;

  &.font-size-medium {
    font-size: 0.875rem;
  }
}

mat-error.caption {
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, 'schibstedgroteskMedium', Tahoma, Arial, sans-serif));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  color: var(--mdc-theme-error, colors.$event-critical);
  opacity: 1;
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
}

a {
  text-decoration: underline;
  color: colors.$primary-text-color;
}


h4,
h2,
a,
.caption,
span,
p {
  &.dark {
    color: colors.$secondary-text-color;
  }

  &.light {
    color: colors.$primary-text-color;
  }
}

.disabled {
  &.caption {
    color: colors.$secondary-text-color-disabled;

    &.dark {
      color: colors.$secondary-text-color;
    }

    &.light {
      color: colors.$primary-text-color-disabled;
    }
  }
}


.success {
  color: colors.$event-success !important;

  a,
  p,
  h1,
  h2,
  h4,
  h4,
  h4,
  h6,
  .caption,
  .overline,
  .subtitle {
    color: colors.$event-success !important;
  }
}

.critical {
  color: colors.$event-critical !important;

  a,
  p,
  h1,
  h2,
  h4,
  h4,
  h4,
  h6,
  .caption,
  .overline,
  .subtitle {
    color: colors.$event-critical !important;
  }
}

.attention {
  color: colors.$event-attention !important;

  a,
  p,
  h1,
  h2,
  h4,
  h4,
  h4,
  h6,
  .caption,
  .overline,
  .subtitle {
    color: colors.$event-attention !important;
  }
}

.status-color {
  &.color-Active {
    color: colors.$event-success;
  }
  &.color-Pending {
    color: colors.$event-attention;
  }
  &.color-Rejected {
    color: colors.$event-critical;
  }
}

.inherit-font-size {
  font-size: inherit;
}

.italics {
  font-style: italic;
}

.dark {
  color: white;
}

.accent {
  color: colors.$accent-color !important;
}
