@use 'colors';

@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icon-fonts/icomoon.eot?to7r93');
  src: url('../assets/fonts/icon-fonts/icomoon.eot?to7r93#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icon-fonts/icomoon.ttf?to7r93') format('truetype'),
    url('../assets/fonts/icon-fonts/icomoon.woff?to7r93') format('woff'),
    url('../assets/fonts/icon-fonts/icomoon.svg?to7r93#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  shape-rendering: crispEdges;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.light {
    color: colors.$primary-text-color;
  }

  &.dark {
    color: colors.$secondary-text-color;
  }

  &.fs14 {
    font-size: 14px;
  }

  &.fs18 {
    font-size: 18px;
  }

  &.fs40 {
    font-size: 40px;
  }
}

.icon-list_alt:before {
  content: "\e900";
}
.icon-short_text:before {
  content: "\e941";
}
.icon-bar_chart:before {
  content: "\e942";
}
.icon-home:before {
  content: "\e943";
}
.icon-admin_panel_settings:before {
  content: "\e944";
}
.icon-paid:before {
  content: "\e901";
}
.icon-assignment:before {
  content: "\e902";
}
.icon-refresh:before {
  content: "\e903";
}
.icon-outline-business:before {
  content: "\e904";
}
.icon-outline-cancel:before {
  content: "\e905";
}
.icon-outline-delete:before {
  content: "\e906";
}
.icon-outline-description:before {
  content: "\e907";
}
.icon-outline-get_app:before {
  content: "\e908";
}
.icon-outline-help_outline:before {
  content: "\e909";
}
.icon-outline-notifications:before {
  content: "\e90a";
}
.icon-outline-power:before {
  content: "\e90b";
}
.icon-outline-settings:before {
  content: "\e90c";
}
.icon-outline-store_mall_directory:before {
  content: "\e90d";
}
.icon-outline-wb_sunny:before {
  content: "\e90e";
}
.icon-Price:before {
  content: "\e90f";
}
.icon-Ramp_Down_custom:before {
  content: "\e910";
}
.icon-Ramp_Up_custom:before {
  content: "\e911";
}
.icon-schedule:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-Sure_Amount_custom:before {
  content: "\e914";
}
.icon-Trade_Sell_default:before {
  content: "\e915";
}
.icon-add_shopping_cart:before {
  content: "\e916";
}
.icon-arrow_back:before {
  content: "\e917";
}
.icon-arrow_downward:before {
  content: "\e918";
}
.icon-arrow_drop_down:before {
  content: "\e919";
}
.icon-arrow_drop_up:before {
  content: "\e91a";
}
.icon-arrow_forward:before {
  content: "\e91b";
}
.icon-arrow_left:before {
  content: "\e91c";
}
.icon-arrow_right:before {
  content: "\e91d";
}
.icon-arrow_upward:before {
  content: "\e91e";
}
.icon-Asset_class_Combined_heat_power_plant_custom:before {
  content: "\e91f";
}
.icon-Asset_class_Diesel_generator_custom:before {
  content: "\e920";
}
.icon-Asset_class_Other_generation_custom:before {
  content: "\e921";
}
.icon-Asset_class_Other_renewable_generation_custom:before {
  content: "\e922";
}
.icon-Asset_class_Wind_turbine_custom:before {
  content: "\e923";
}
.icon-Asset_custom:before {
  content: "\e924";
}
.icon-Battery_custom:before {
  content: "\e925";
}
.icon-calendar_today:before {
  content: "\e926";
}
.icon-check:before {
  content: "\e927";
}
.icon-chevron_left:before {
  content: "\e928";
}
.icon-chevron_right:before {
  content: "\e929";
}
.icon-close:before {
  content: "\e92a";
}
.icon-create:before {
  content: "\e92b";
}
.icon-error_outline:before {
  content: "\e92c";
}
.icon-expand_less:before {
  content: "\e92d";
}
.icon-expand_more:before {
  content: "\e92e";
}
.icon-graph:before {
  content: "\e92f";
}
.icon-marker:before {
  content: "\e930";
}
.icon-Meterpoint_custom:before {
  content: "\e931";
}
.icon-more_horiz:before {
  content: "\e932";
}
.icon-outline-account_circle:before {
  content: "\e933";
}
.icon-outline-alarm:before {
  content: "\e934";
}
.icon-cloud_upload:before {
  content: "\e935";
}
.icon-laptop_mac:before {
  content: "\e936";
}
.icon-cloud_download:before {
  content: "\e937";
}
.icon-pin_drop:before {
  content: "\e938";
}
.icon-vpn_key:before {
  content: "\e939";
}
.icon-map:before {
  content: "\e93a";
}
.icon-show_chart:before {
  content: "\e93b";
}
.icon-policy-line:before {
  content: "\e93c";
}
.icon-inspect:before {
  content: "\e93d";
}
.icon-document-certificate1:before {
  content: "\e93e";
}
.icon-supervisor_account:before {
  content: "\e93f";
}
.icon-group_work:before {
  content: "\e940";
}
.icon-warning:before {
  content: "\ea07";
}
