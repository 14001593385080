@use 'scss/colors';
@use 'scss/theme-palette';

.nodes-tabs {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  height: 40px;

  button {
    height: 100%;
    flex: 1;
    border-radius: 0;
    background: colors.$dark-background-secondary;
  }

  .active {
    background: theme-palette.$primary-800;
    color: white;
  }
}
