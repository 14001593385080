@use "sass:color";
@use "scss/colors";
@use "scss/variables";

.nodes-toggle-button {
  background-color: colors.$toggle-button-off;
  padding: 0.5rem 0.6rem;
  cursor: pointer;
  color: colors.$primary-text-color;
  text-align: center;
  border: 1px solid colors.$light-border;
  border-radius: variables.$default-border-radius;

  &:hover:not(.disabled) {
    background-color: color.adjust(colors.$toggle-button-off, $lightness: -10%);
  }

  &.active:not(.disabled-item) {
    background-color: colors.$toggle-button-on;
    color: white;

    &:hover:not(.disabled) {
      background-color: color.adjust(colors.$toggle-button-on, $lightness: -12%);
    }
  }

  &.disabled-item {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.disabled:not(.disabled-item) {
    opacity: 0.8;
    cursor: default;
  }
}
