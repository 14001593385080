@use "sass:color";
@use "theme-palette";

@forward 'theme-palette';

$disabled: theme-palette.$gray-300;
$accent-color: theme-palette.$logo-green;

// Backgrounds
$sorting-header-background: theme-palette.$primary-600;
$header-background: theme-palette.$primary-500;
$dark-background-secondary: theme-palette.$primary-600;
$panel-background: theme-palette.$primary-50;
$component-background: theme-palette.$primary-25;
$background-hover: theme-palette.$primary-50;
$panel-background-hover: theme-palette.$primary-100;
$disabled-background: theme-palette.$gray-100;
$white-background: white;

$file-drop-hover: theme-palette.$primary-100;

// Borders
$extra-light-border: theme-palette.$gray-50;
$light-border: theme-palette.$gray-200;
$component-border: theme-palette.$gray-300;
$header-border: theme-palette.$gray-400;
$dark-header-border: theme-palette.$primary-900;

//Events
$event-success: theme-palette.$secondary-green-600;
$event-critical: theme-palette.$error-500;
$event-info: theme-palette.$secondary-blue-500;
$event-attention: theme-palette.$warning-500;
$event-disabled: $disabled;

//Text-colors
$header-text-color: theme-palette.$primary-500;
$primary-text-color: theme-palette.$primary-500;
$primary-text-color-disabled: color.adjust($primary-text-color, $lightness: -50%);
$primary-text-color-hover: color.adjust($background-hover, $lightness: -50%);
$secondary-text-color: white;
$secondary-text-color-disabled: theme-palette.$gray-500;
$accent-text-color: $accent-color;
$placeholder-text-color: theme-palette.$gray-500;
$text-black: theme-palette.$gray-900;
$text-black-disabled: rgba($text-black, 0.5);
$text-white: white;
$text-white-disabled: rgba($text-white, 0.5);


//Button
$button-primary: theme-palette.$primary-500;
$button-primary-pressed: theme-palette.$primary-600;
$button-primary-hover: theme-palette.$primary-800;
$button-primary-active: $button-primary-pressed;
$button-primary-disabled: $disabled;

$button-primary-dark: white;
$button-primary-dark-pressed: theme-palette.$gray-100;
$button-primary-dark-disabled: theme-palette.$gray-800;

$button-secondary: white;
$button-secondary-pressed: theme-palette.$primary-600;
$button-secondary-hover: theme-palette.$primary-800;
$button-secondary-active: $button-secondary-pressed;
$button-secondary-disabled: $disabled;


$button-secondary-dark: rgba(255, 255, 255, 0.15);
$button-secondary-dark-pressed: rgba(174, 174, 172, 0.3);
$button-secondary-dark-disabled: rgba(118, 118, 116, 0.25);

$button-buy: theme-palette.$secondary-green-600;
$button-buy-pressed: color.adjust($button-buy, $lightness: -5%);
$button-buy-disabled: theme-palette.$gray-400;

$button-sell: theme-palette.$secondary-blue-500;
$button-sell-pressed: color.adjust($button-sell, $lightness: -5%);
$button-sell-disabled: theme-palette.$gray-400;

$card-hover: theme-palette.$primary-100;
$card-highlighted: theme-palette.$primary-50;

$toggle-button-on: theme-palette.$primary-500;
$toggle-button-off: theme-palette.$primary-100;
