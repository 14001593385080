@use "scss/colors";

.map-marker {
  position: absolute;
  background-color: transparent;

  .asset-icon {
    color: colors.$header-background;
    background-color: colors.$component-background;
    border: solid 8px white;
    border-radius: 50%;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
}

.map-marker-circle,
.map-marker-circle-highlighted {
  padding: 2px;

  .map-marker-circle-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: var(--stroke-size) solid var(--border-color);
    background: var(--fill-color);
    opacity: var(--opacity);

    svg {
      height: 50%;
      width: 50%;
    }

    &.green {
      border: 2px solid
    }
  }
}

.map-marker-circle-highlighted {
  .map-marker-circle-inner {
    border: 4px solid var(--border-color);
  }
}

.icon-marker-group {
  font-size: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    position: absolute;
    color: white;
    font-size: 12px;
    top: 9px;
  }

  &.green {
    color: colors.$event-success;
  }

  &.blue {
    color: colors.$header-background;
  }

  &.grey {
    color: grey;
  }
}

.icon-marker-map {
  font-size: 69px;

  &.green {
    color: colors.$event-success;
  }

  &.blue {
    color: colors.$header-background;
  }

  &.grey {
    color: grey;
  }
}

.icon-marker-small-map-highlighted,
.icon-marker-small-map {
  font-size: 25px;

  &.green {
    color: colors.$event-success;
  }

  &.blue {
    color: colors.$header-background;
  }

  &.grey {
    color: grey;
  }
}

.icon-marker-small-map-highlighted {
  .icon-marker-small-map {
    font-size: 30px;
  }
}

.leaflet-interactive:hover {
  transition: all 100ms;
}

.crosshair-cursor-enabled {
  cursor: crosshair;
}

.pointer-cursor-enabled {
  cursor: pointer;
}
