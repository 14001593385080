@use 'scss/colors';

a:hover {
  color: colors.$primary-text-color-hover;
}

a.link {
  &:hover {
    color: colors.$primary-text-color-hover;
  }
}


nav {
  a {
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
}

.a__no-style {
  text-decoration: none;
  color: inherit;
}
