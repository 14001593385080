@use "sass:color";
@use 'scss/_colors.scss';
@use 'scss/_variables.scss';

button {
  cursor: pointer;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background-color: colors.$button-primary;
  color: colors.$secondary-text-color;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border: 1px solid colors.$button-primary;
  border-radius: variables.$default-border-radius;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    background-color: colors.$button-primary-hover;
    border-color: colors.$button-primary-hover;
  }

  &:disabled {
    color: white;
    background-color: colors.$button-primary-disabled;
    border-color: colors.$button-primary-disabled;
    cursor: inherit;
  }

  &.compact {
    width: fit-content;
  }

  &.secondary {
    color: colors.$primary-text-color;
    background-color: colors.$button-secondary;
    border-color: colors.$primary-text-color-hover;

    &:hover,
    &.active {
      background-color: colors.$button-secondary-hover;
      color: white;
    }

    &:disabled {
      background-color: colors.$button-secondary-disabled;
      border-color: colors.$button-secondary-disabled;
      color: white;
    }
  }

  &.tertiary {
    color: colors.$primary-text-color;
    background-color: inherit;
    border: none;
    text-decoration: underline;

    &:hover {
      background-color: inherit;
      color: colors.$primary-text-color;
    }

    &:disabled {
      background-color: inherit;
      color: colors.$button-secondary-disabled;
    }
  }

  &.ghost {
    color: colors.$gray-50;
    background-color: inherit;
    border: none;

    &:hover {
      color: colors.$primary-text-color;
      background-color: colors.$primary-300;
    }

    &:disabled {
      background-color: inherit;
      color: colors.$button-secondary-disabled;
    }
  }

  &.small {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  &.danger, &.critical {
    background-color: colors.$event-critical;
    border-color: colors.$event-critical;
    color: white !important;

    &:hover {
      background-color: color.adjust(colors.$event-critical, $lightness: -5%);
      border-color: color.adjust(colors.$event-critical, $lightness: -5%);
    }

    &:disabled {
      background-color: colors.$button-primary-disabled;
      border-color: colors.$button-primary-disabled;
    }
  }

  &.attention {
    background-color: colors.$event-attention;
    border-color: colors.$event-attention;
    color: white !important;

    &:hover {
      background-color: color.adjust(colors.$event-attention, $lightness: -5%);
      border-color: color.adjust(colors.$event-attention, $lightness: -5%);
    }

    &:disabled {
      background-color: colors.$button-primary-disabled;
      border-color: colors.$button-primary-disabled;
    }
  }

  &.success {
    background-color: colors.$event-success;
    border-color: colors.$event-success;
    color: white !important;

    &:hover {
      background-color: color.adjust(colors.$event-success, $lightness: -5%);
      border-color: color.adjust(colors.$event-success, $lightness: -5%);
    }

    &:disabled {
      background-color: colors.$button-primary-disabled;
      border-color: colors.$button-primary-disabled;
    }
  }

  &.buy {
    background-color: colors.$button-buy;
    border-color: colors.$button-buy;
    color: colors.$secondary-text-color;

    &:hover {
      background-color: colors.$button-buy-pressed;
      border-color: colors.$button-buy-pressed;
    }

    &:disabled {
      background-color: colors.$button-buy-disabled;
      border-color: colors.$button-buy-disabled;
    }
  }

  &.sell {
    background-color: colors.$button-sell;
    border-color: colors.$button-sell;
    color: colors.$secondary-text-color;

    &:hover {
      background-color: colors.$button-sell-pressed;
      border-color: colors.$button-sell-pressed;
    }

    &:disabled {
      background-color: colors.$button-sell-disabled;
      border-color: colors.$button-sell-disabled;
    }
  }

  &.dark {
    background-color: colors.$button-primary-dark;
    border-color: colors.$button-primary-dark;
    color: colors.$primary-text-color;

    &:hover {
      background-color: colors.$button-primary-dark-pressed;
      border-color: colors.$button-primary-dark-pressed;
    }

    &:disabled {
      color: colors.$secondary-text-color-disabled;
      background-color: colors.$button-primary-dark-disabled;
      border-color: colors.$button-primary-dark-disabled;
    }

    &.secondary {
      color: colors.$secondary-text-color;
      background-color: colors.$button-secondary-dark;
      border-color: colors.$button-secondary-dark;

      &:hover {
        background-color: colors.$button-secondary-dark-pressed;
        border-color: colors.$button-secondary-dark-pressed;
      }

      &:disabled {
        color: colors.$primary-text-color-disabled;
        background-color: colors.$button-secondary-dark-disabled;
        border-color: colors.$button-secondary-dark-disabled;
      }
    }
  }

  &.button__tall {
    height: auto;
  }

  &.button__icon {
    position: relative;
    padding: 0.5em;
    color: colors.$primary-text-color;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    width: 40px;
    height: 40px;

    &.critical {
      outline: 1px solid colors.$event-critical;
      color: colors.$primary-text-color !important;
    }
    &.attention {
      outline: 1px solid colors.$event-attention;
      color: colors.$primary-text-color !important;
    }
    &.info {
      outline: 1px solid colors.$event-info;
      color: colors.$primary-text-color !important;
    }
    &.success {
      outline: 1px solid colors.$event-success;
      color: colors.$primary-text-color !important;
    }

    &:hover {
      background-color: colors.$button-secondary-dark-pressed;
    }

    &.dark {
      background-color: transparent;
      color: colors.$secondary-text-color;

      i {
        color: colors.$secondary-text-color;
      }

      &:hover {
        background-color: colors.$button-secondary-dark-pressed;
      }

      &.outlined {
        outline: 1px solid colors.$secondary-text-color;
      }
    }
  }

  &.button__no-style {
    background: inherit;
    color: inherit;
    text-transform: none;
    font-family: inherit;
    text-align: inherit;
    border: none;
    border-radius: 0;
    transition: none;

    &:hover {
      background: inherit;
      color: inherit;
    }
  }
}

a[role="button"] {
  @extend button;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    color: white;
  }

  &.button__no-style {
    @extend .button__no-style;
    padding: 0;
  }
}

.leaflet-control-zoom {
  button {
    border-radius: 0;
  }
}
