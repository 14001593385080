/* Color styles */
$gray-25: #FAFAFA;
$gray-50: #F4F4F4;
$gray-100: #EEEEEE;
$gray-200: #ececec;
$gray-300: #BDBDBD;
$gray-400: #A5A5A5;
$gray-500: #8B8B8B;
$gray-600: #717171;
$gray-700: #5F5F5F;
$gray-800: #4C4C4C;
$gray-900: #383838;

$primary-25: #F9FAFA;
$primary-50: #ECEEEF;
$primary-100: #CFD6D9;
$primary-200: #B5BEC4;
$primary-300: #9AA7AE;
$primary-400: #7D8D97;
$primary-500: #264252;
$primary-600: #223B4A;
$primary-700: #1E3542;
$primary-800: #1B2E39;
$primary-900: #172831;

$error-500: #F04438;
$warning-500: #F79009;
$success-500: #12B76A;

$secondary-green-25: #F6FCF2;
$secondary-green-50: #E0F4D4;
$secondary-green-100: #B1E08B;
$secondary-green-200: #A4DB77;
$secondary-green-300: #97D664;
$secondary-green-400: #8AD150;
$secondary-green-500: #7DCC3D;
$secondary-green-600: #71B837;
$secondary-green-700: #64A331;
$secondary-green-800: #588F2B;
$secondary-green-900: #4B7A25;

$secondary-blue-25: #F8FAFB;
$secondary-blue-50: #E6EFF3;
$secondary-blue-100: #C3D8E1;
$secondary-blue-200: #A0C2D0;
$secondary-blue-300: #7DACBF;
$secondary-blue-400: #5793AC;
$secondary-blue-500: #13678A;
$secondary-blue-600: #115D7C;
$secondary-blue-700: #0F526E;
$secondary-blue-800: #0D4861;
$secondary-blue-900: #0B3E53;

$logo-green: #7DCD3E;

/* Text-size styles */
/* base size: large-menu-default (16px) */
$large-menu-default: 1rem;
$large-menu-active: 0.88rem;
$large-button-text: 1.12rem;
$large-paragraph-text: 1.12rem;
$large-h1: 2.25rem;
$large-h4: 1.5rem;
$large-h4: 1.25rem;
$large-ingress: 1.25rem;
$large-input-text: 1rem;
$large-breadcrumb: 0.75rem;
$large-display: 4.5rem;
$large-c-t-a: 1.25rem;
$large-h2: 1.88rem;
$large-label: 0.88rem;
$large-titel: 1.25rem;
$large-subtitle: 1rem;
$large-sublabel: 0.88rem;
$large-h2-c-t-a: 1.88rem;
$small-button-text: 1rem;
$small-h2: 1.62rem;
$small-paragraph-text: 1rem;
$small-h1: 1.88rem;
$small-h4: 1.38rem;
$small-h4: 1.25rem;
$small-ingress: 1.12rem;
$small-input-text: 1rem;
$small-breadcrumb: 0.62rem;
$small-main-menu: 1.25rem;
$small-display: 3.25rem;
$small-c-t-a: 1.12rem;
$small-h2-c-t-a: 1.62rem;
