@use "scss/colors";
@use 'scss/variables';

form {
  &.nodes-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .form-body {
      overflow-y: auto;
      padding: 24px;
      height: 100%;

      .form-subsection-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .subtitle {
          width: auto;
        }
      }

      .form-subsection {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .subtitle {
        width: 100%;
      }

      mat-form-field, mat-form-group, .form-item {
        width: 100%;

        &.half-width {
          width: calc(50% - variables.$default-gap);
        }

        &.third-width {
          width: calc(33% - variables.$default-gap);
        }

        &.quarter-width {
          width: calc(24% - variables.$default-gap);
        }
      }

      .form-item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.form-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-top: solid 1px colors.$component-border;
  flex: 0 0 72px;

  .actions {
    display: flex;
    gap: variables.$default-gap;
  }
}
