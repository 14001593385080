@use 'colors';

.nodes-dropdown-menu {
  display: inline-flex;
  flex-direction: column;
  min-width: 180px;
  max-width: 280px;
  background-color: colors.$component-background;
  padding: 6px 0;
  border: 1px solid colors.$light-border;

  .menu-title {
    padding: 0 1rem;
  }

  .menu-item {
    text-decoration: none;
    background-color: transparent;
    color: colors.$button-primary;
    border: none;
    cursor: pointer;

    user-select: none;
    padding: .5rem 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &:hover {
      background-color: colors.$background-hover;
    }

    &.active {
      background-color: colors.$background-hover;
    }
  }
}
